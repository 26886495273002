@font-face {
    font-family: "Open Sans";
    src: url("./../fonts/open-sans-v17-latin-300.woff2") format("woff2"),
         url("./../fonts/open-sans-v17-latin-300.woff") format("woff"),
         url("./../fonts/open-sans-v17-latin-300italic.woff2") format("woff2"),
         url("./../fonts/open-sans-v17-latin-300italic.woff") format("woff"),

         url("./../fonts/open-sans-v17-latin-600.woff2") format("woff2"),
         url("./../fonts/open-sans-v17-latin-600.woff") format("woff"),
         url("./../fonts/open-sans-v17-latin-600italic.woff2") format("woff2"),
         url("./../fonts/open-sans-v17-latin-600italic.woff") format("woff"),

         url("./../fonts/open-sans-v17-latin-800.woff2") format("woff2"),
         url("./../fonts/open-sans-v17-latin-800.woff") format("woff"),
         url("./../fonts/open-sans-v17-latin-800italic.woff2") format("woff2"),
         url("./../fonts/open-sans-v17-latin-800italic.woff") format("woff"),

         url("./../fonts/open-sans-v17-latin-regular.woff2") format("woff2"),
         url("./../fonts/open-sans-v17-latin-regular.woff") format("woff"),
         url("./../fonts/open-sans-v17-latin-italic.woff2") format("woff2"),
         url("./../fonts/open-sans-v17-latin-italic.woff") format("woff");

     font-display: swap;
  }

  @font-face {
    font-family: "Lato";
    src:
         url("./../fonts/lato-v16-latin-regular.woff2") format("woff2"),
         url("./../fonts/lato-v16-latin-regular.woff") format("woff"),
         url("./../fonts/lato-v16-latin-italic.woff2") format("woff2"),
         url("./../fonts/lato-v16-latin-italic.woff") format("woff"),

         url("./../fonts/lato-v16-latin-700.woff2") format("woff2"),
         url("./../fonts/lato-v16-latin-700.woff") format("woff"),
         url("./../fonts/lato-v16-latin-700italic.woff2") format("woff2"),
         url("./../fonts/lato-v16-latin-700italic.woff") format("woff");
     font-display: swap;
  }

  @font-face {
    font-family: "Merriweather";
    src:
         url("./../fonts/merriweather-v21-latin-regular.woff2") format("woff2"),
         url("./../fonts/merriweather-v21-latin-regular.woff") format("woff"),
         url("./../fonts/merriweather-v21-latin-italic.woff2") format("woff2"),
         url("./../fonts/merriweather-v21-latin-italic.woff") format("woff"),

         url("./../fonts/merriweather-v21-latin-700.woff2") format("woff2"),
         url("./../fonts/merriweather-v21-latin-700.woff") format("woff"),
         url("./../fonts/merriweather-v21-latin-700italic.woff2") format("woff2"),
         url("./../fonts/merriweather-v21-latin-700italic.woff") format("woff");
     font-display: swap;
  }

  @font-face {
    font-family: "Gilroy";
    font-weight: normal;
    font-style: normal;
    src:         
         url("./../fonts/Gilroy-Regular.ttf") format("truetype");
     font-display: swap;
  }

  @font-face {
    font-family: "Gilroy";
    font-weight: normal;
    font-style: italic;
    src:         
         url("./../fonts/Gilroy-RegularItalic.ttf") format("truetype");
     font-display: swap;
  }
  
  @font-face {
    font-family: "Gilroy";
    font-weight:bold;
    font-style: normal;
    src:
         url("./../fonts/Gilroy-Bold.ttf") format("truetype");
     font-display: swap;
  }
  
  @font-face {
    font-family: "Gilroy";
    font-weight:bold;
    font-style: italic;
    src:
         url("./../fonts/Gilroy-BoldItalic.ttf") format("truetype");
     font-display: swap;
  }
  
  @font-face {
    font-family: "Gilroy";
    font-weight:bolder;
    font-style: normal;
    src:
         url("./../fonts/Gilroy-ExtraBold.ttf") format("truetype");
     font-display: swap;
  }
  
  @font-face {
    font-family: "Gilroy";
    font-weight:lighter;
    font-style: normal;
    src:
         url("./../fonts/Gilroy-Light.ttf") format("truetype");
     font-display: swap;
  }

  @font-face {
    font-family: "Gilroy Black";
    src:
         url("./../fonts/Gilroy-Black.ttf") format("truetype"),
         url("./../fonts/Gilroy-BlackItalic.ttf") format("truetype");
     font-display: swap;
  }

  @font-face {
    font-family: "Gilroy Light";
    font-weight: lighter;
    font-style: normal;
    src:         
         url("./../fonts/Gilroy-Light.ttf") format("truetype"),
         url("./../fonts/Gilroy-LightItalic.ttf") format("truetype");
     font-display: swap;
  }